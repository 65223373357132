<template>
  <!-- Start Page Title Area -->
  <div class="page-title-area">
    <div class="container">
      <div class="page-title-content">
        <h2>{{sectionCatItem.title}}</h2>
        <h4 class="active">Create Session</h4>
      </div>
    </div>
  </div>
  <!-- End Page Title Area -->


  <div class="page-content">

    <div class="row">

      <!-- block start -->
      <div class="col-sm">
        <h6 class="mb-0 text-uppercase">Select categories</h6>
        <hr>

        <div v-if="Object.keys(sectionCatItem).length !== 0">

          <div class="form-check">
            <div v-for="section in sectionCatItem.sections" v-bind:key="section.id" >
              <input type="checkbox" class="form-check-input" :id="section.id" :value="section.id" v-model="selectedSections">
              <label class="form-check-label" :for="section.id">{{section.title}}</label>
            </div>
          </div>

        </div>

      </div>
      <!-- block end -->


      <!-- block start -->
      <div class="col-sm">
        <h6 class="mb-0 text-uppercase">Difficulty Level</h6>
        <hr>

        <div v-if="Object.keys(sectionCatItem).length !== 0">

          <div class="form-check">

            <div>
              <input type="checkbox" id="difficulty-1" value="1" v-model="selectedDifficulties" class="form-check-input"><label class="form-check-label" for="difficulty-1">easy</label>
            </div>
            <div>
              <input type="checkbox" id="difficulty-2" value="2" v-model="selectedDifficulties" class="form-check-input"><label class="form-check-label" for="difficulty-2">standard</label>
            </div>
            <div>
              <input type="checkbox" id="difficulty-3" value="3" v-model="selectedDifficulties" class="form-check-input"><label class="form-check-label" for="difficulty-3">advanced</label>
            </div>
            <div>
              <input type="checkbox" id="difficulty-4" value="4" v-model="selectedDifficulties" class="form-check-input"><label class="form-check-label" for="difficulty-4">hard</label>
            </div>

          </div>

        </div>

      </div>
      <!-- block end -->

      <!-- block start -->
      <div class="col-sm">
        <h6 class="mb-0 text-uppercase">Amount of questions</h6>
        <hr>

        <div class="mb-3">
          <label for="selectionForMaxQuestions" class="form-label">max: {{selectedMax}}</label>
          <input type="range" class="form-range" min="1" max="40" v-model="selectedMax" id="selectionForMaxQuestions">
        </div>


        <div class="container">

          <div class="row">
            <div class="col-sm-2 form-check">
              <input class="form-check-input" type="radio" id="maxQuestionsRadio1" value="1" v-model="selectedMax"><label class="form-check-label" for="maxQuestionsRadio1">1</label>
            </div>
            <div class="col-sm-2 form-check">
              <input class="form-check-input" type="radio" id="maxQuestionsRadio5" value="5" v-model="selectedMax"><label class="form-check-label" for="maxQuestionsRadio5">5</label>
            </div>
            <div class="col-sm-2 form-check">
              <input class="form-check-input" type="radio" id="maxQuestionsRadio10" value="10" v-model="selectedMax"><label class="form-check-label" for="maxQuestionsRadio10">10</label>
            </div>
            <div class="col-sm-2 form-check">
              <input class="form-check-input" type="radio" id="maxQuestionsRadio20" value="20" v-model="selectedMax"><label class="form-check-label" for="maxQuestionsRadio20">20</label>
            </div>
            <div class="col-sm-2 form-check">
              <input class="form-check-input" type="radio" id="maxQuestionsRadio40" value="40" v-model="selectedMax"><label class="form-check-label" for="maxQuestionsRadio40">40</label>
            </div>
          </div>

        </div>

      </div>
      <!-- block end -->

    </div>

  </div>


  <div class="page-content">

    <div class="row">

      <div class="col-sm">
        <hr>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">

        <div class="d-flex align-items-start flex-column bd-highlight mb-3">
          <div class="p-2">

            <router-link :to="linkTraining(sectionCat)" class="default-btn">
              cancel
            </router-link>

          </div>
        </div>

      </div>
      <div class="col-sm">

        <div class="d-flex align-items-end flex-column bd-highlight mb-3">
          <div class="p-2">
            <button @click="createSession" :disabled="isCreateSessionButtonDisabled" class="default-btn altbgcolor2">{{ createSessionButtonText }}</button>
          </div>
        </div>

      </div>


    </div>


  </div>



</template>


<script>

import axios from "axios";

export default {

  data() {
    return {
      sectionCatItem: {},
      selectedSections: [],
      selectedMax: 20,
      selectedDifficulties: [1,2,3,4],
      createSessionButtonText: 'Create session',
      isCreateSessionButtonDisabled: false // Controls the disabled state of the create session button
    }
  },

  created() {
    this.loadData()
  },


  methods: {
    loadData: function () {
      if (this.loadDone === true) {
        return
      } // skip if already done
      this.loadDone = true

      this.sectionCat = this.$route.params.sectionCat
      console.log("Section cat opened: %s", this.sectionCat)

      // load training meta information
      const url_cat = process.env.VUE_APP_URL_GET_SUBJECTDETAILS + '/' + this.sectionCat
      console.log("GET: " + url_cat)
      axios.get(url_cat, {cache: true}).then(
          res => {
            this.sectionCatItem = res.data.payload.item
            this.loadSectionIdsAsArray()
          }
      ).catch(error => console.log(error));
    },

    loadSectionIdsAsArray: function(){
      this.sectionCatItem.sections.forEach(element => this.selectedSections.push(element.id));
    },

    linkTraining(sectionCat){
      return '/training/'+sectionCat
    },

    createSession: function(){
      console.log('create session button clicked, sending POST ...')
      this.createSessionButtonText = 'Please wait...';
      this.isCreateSessionButtonDisabled = true; // Disable the create session button

      axios.defaults.headers = {
        'Content-Type': 'application/json',
        'Authorization': this.$store.getters.userAccessToken
      }

      const creationParams={
        sections: this.selectedSections,
        difficulties: this.selectedDifficulties,
        max: parseInt( this.selectedMax )
      }
      const creationParamsJson = JSON.stringify(creationParams)

      console.log('Params to be send: %s',creationParamsJson)

      // load training meta information
      const url_crses = process.env.VUE_APP_URL_POST_TRAINING_CREATE_SESSION + '/' + this.sectionCat
      console.log("POST: " + url_crses)
      axios.post(url_crses, creationParams ).then(
          res => {
            const sessionId = res.data.payload.item.session
            console.log('session created: %s', sessionId)

            const url = '/training_session/'+sessionId
            console.log('redirecting to: %s', url)

            this.$router.push(url).then(() => { // this is a hack as page route often to not load, so I refresh the page
              console.log('Updated route', this.$route)
              this.$nextTick(() => {
                window.location.reload()
              });
            });

          }
      ).catch(error => console.log(error));

    },

  }

}

</script>